<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">
        {{ $t("Personal_Information") }}
      </h4>
    </div>
    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <!-- Field: Birth Date -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Birthdate')" label-for="birth-date">
            <b-form-datepicker id="example-datepicker" v-model="userDataInfo.birthday" class="mb-1" :min="min" :max="max"
              :locale="$i18n.locale" label-help="" /></b-form-group>
        </b-col>
        <!-- Field: Mobile -->
        <!-- <b-col cols="12" md="6" lg="4">
                      <b-form-group label="Mobile" label-for="mobile">
                        <b-form-input id="mobile" v-model="userDataInfo.mobile" />
                      </b-form-group>
                    </b-col> -->
        <!-- Field: Website -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Siblings')" label-for="siblings">
            <b-form-input id="siblings" v-model="userDataInfo.siblings" type="number" />
          </b-form-group>
        </b-col>
        <!-- Field: Language -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Native Language')" label-for="language">
            <v-select v-model="userDataInfo.language" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="languageOptions" :clearable="false" input-id="language" />
          </b-form-group>
        </b-col>
        <!-- Field: Gender -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Gender')" label-for="gender" label-class="mb-1">
            <b-form-radio-group id="gender" v-model="userDataInfo.gender" :options="genderOptions" value-field="value" />
          </b-form-group>
        </b-col>
        <!-- Field: Contact Options -->
        <!-- <b-col cols="12" md="6" lg="4">
                      <b-form-group label="Contact Options" label-for="contact-options" label-class="mb-1">
                        <b-form-checkbox-group id="contact-options" v-model="userDataInfo.contactOptions" :options="contactOptionsOptions" />
                      </b-form-group>
                    </b-col> -->
      </b-row>
      <!-- Field: Guardian -->
      <div class="d-flex mt-2 mb-2">
        <feather-icon icon="BookIcon" size="19" />
        <h4 class="mb-0 ml-50">
          {{ $t("Guardian Information") }}
        </h4>
      </div>
      <b-row>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Parent Name')" label-for="guardian-name">
            <b-form-input id="guardian-name" v-model="userDataInfo.guardian[0].name" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Parent Age')" label-for="guardian-age">
            <b-form-input id="guardian-age" v-model="userDataInfo.guardian[0].age" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Parent Education')" label-for="guardian-education">
            <b-form-input id="guardian-education" v-model="userDataInfo.guardian[0].education" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Parent Phone')" label-for="guardian-phone">
            <b-form-input id="guardian-name" v-model="userDataInfo.guardian[0].phone" :formatter="phoneFormatter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Parent Profession')" label-for="guardian-work">
            <b-form-input id="guardian-work" v-model="userDataInfo.guardian[0].work" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Parent E-mail Address')" label-for="guardian-mail">
            <b-input-group>
              <b-form-input id="guardian-mail" v-model="userDataInfo.guardian[0].email" @blur="emailControl" />
              <b-input-group-append v-if="userDataInfo.parentAccountCreated">
                <b-button variant="warning" v-b-tooltip.hover.v-outline-primary @click="resetParentPassword"
                  :title="$t('Reset Parent Password')">
                  <feather-icon icon="LockIcon" size="14" />
                </b-button>
              </b-input-group-append>
              <b-input-group-append v-if="
                  !userDataInfo.parentAccountCreated &&
                  userDataInfo.guardian[0].name &&
                  userDataInfo.guardian[0].email
                ">
                <b-button variant="outline-primary" @click="createParentAccount" v-b-tooltip.hover.v-primary
                  :title="$t('Create Parent Account')">
                  <feather-icon icon="UserPlusIcon" size="14" />
                  <!-- <span>{{ $t("Create Parent Account") }}</span> -->
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Header: Guardian Info  -->
      <div class="d-flex mt-2">
        <feather-icon icon="BookIcon" size="19" />
        <h4 class="mb-0 ml-50">
          {{ $t("School Information") }}
        </h4>
      </div>
      <!-- Form School Info -->
      <b-row class="mt-2">
        <!-- Field: School Name -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('School Name')" label-for="school-name">
            <b-form-input id="school-name" v-model="userDataInfo.school.name" />
          </b-form-group>
        </b-col>
        <!-- Field: School Address -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('School Class')" label-for="school-class">
            <b-form-input id="school-class" v-model="userDataInfo.school.class" />
          </b-form-group>
        </b-col>
        <!-- Field: School Email -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Teacher Name')" label-for="teacher-name">
            <b-form-input id="teacher-name" v-model="userDataInfo.teacher.name" />
          </b-form-group>
        </b-col>
        <!-- Field: School Phone -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Teacher Phone')" label-for="teacher-phone">
            <b-form-input id="teacher-phone" v-model="userDataInfo.teacher.phone" :formatter="phoneFormatter" />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Header: Address  Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">{{ $t("Address Information") }}</h4>
      </div>
      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Address Line 1 -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Address Line 1')" label-for="address-line-1">
            <b-form-input id="address-line-1" v-model="userData.address" />
          </b-form-group>
        </b-col>
        <!-- Field: Address Line 2 -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Address Line 2')" label-for="address-line-2">
            <b-form-input id="address-line-2" v-model="userDataInfo.addressLine2"
              :placeholder="$t('Apartment, or floor')" />
          </b-form-group>
        </b-col>
        <!-- Field: Postcode -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('Zip Code')" label-for="postcode">
            <b-form-input id="postcode" v-model="userDataInfo.postcode" type="number" placeholder="41000" />
          </b-form-group>
        </b-col>
        <!-- Field: City -->
        <!-- <b-col cols="12" md="6" lg="4">
                      <b-form-group label="Şehir" label-for="city">
                        <b-form-input id="city" v-model="userDataInfo.city" :options="languageOptions" />
                      </b-form-group>
                    </b-col> -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('City')" label-for="cities">
            <v-select v-model="userDataInfo.cities" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="cities"
              :clearable="false" input-id="cities" />
          </b-form-group>
        </b-col>
        <!-- Field: State -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group :label="$t('State')" label-for="state">
            <b-form-input id="state" v-model="userDataInfo.state" :placeholder="$t('State')" />
          </b-form-group>
        </b-col>
        <!-- Field: Country -->
      </b-row>
      <b-row class="mt-2">
        <b-col>
          <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="saveUserData">
            {{ $t("Save Changes") }}
          </b-button>
          <!-- <b-button variant="outline-secondary" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
            {{ $t("Reset") }}
          </b-button> -->
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import i18n from "@/libs/i18n";

const {
  axiosRoutes: { subject: path, parent: parentPath },
} = require("/G_CONFIG");

const cities = require("./cities.js");

export default {
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    minDate.setFullYear(minDate.getFullYear() - 17);

    // 15th in two months
    const maxDate = new Date(today);
    maxDate.setFullYear(maxDate.getFullYear() + -5);

    return {
      min: minDate,
      max: maxDate,
      userParentEmail: "",
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const userDataInfo = ref({
      name: "",
      age: 0,
      siblings: 0,
      address: "",
      language: "",
      school: {
        name: "",
        class: "",
      },
      teacher: {
        name: "",
        phone: "",
      },
      guardian: [
        {
          name: "",
          age: 0,
          degree: "",
          education: "",
          work: "",
          phone: "",
          idNumber: "",
        },
      ],
      birthday: "",
      subjectClass: "",
    });
    const langs = ["English", "Turkish", "Arabic", "Russian", "Azerbaijani"];
    const languageOptions = langs.map((lang) => {
      return i18n.t(lang);
    });

    const genderOptions = [
      { text: i18n.t("Boy"), value: i18n.t("Boy"), selected: true },
      { text: i18n.t("Girl"), value: i18n.t("Girl") },
    ];

    const contactOpts = ["Email", "Message", "Phone"];

    const contactOptionsOptions = contactOpts.map((contact) => {
      return i18n.t(contact);
    });

    return {
      userDataInfo,
      languageOptions,
      genderOptions,
      contactOptionsOptions,
      cities,
    };
  },
  created() {
    this.userDataInfo = { ...this.userDataInfo, ...this.userData };
    this.userParentEmail = this.userData.guardian[0].email;
  },
  methods: {
    saveUserData() {
      axios.patch(`${path.edit}/${this.$route.params.id}`, this.userDataInfo);
      // console.log(this.userDataInfo);
      this.$swal({
        title: this.$t("Changes Saved Successfully"),
        text: this.$t("Do you wish to continue editing?"),
        icon: "success",
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        // timer: 3000,
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-info ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.dismiss === "cancel") {
          this.$router.back();
        }
      });
      // setTimeout(() => {
      //   this.$router.back()
      // }, 3000)
    },
    phoneFormatter(phone) {
      return phone.replace(/\D/g, "").slice(0, 10);
    },
    createParentAccount() {
      // this.$toast({
      //   component: ToastificationContent,
      //   props: {
      //     title: this.$t("Not Implemented Yet"),
      //     icon: "UserPlusIcon",
      //     variant: "danger",
      //   },
      // });

      axios
        .post(`${parentPath.new}/${this.userDataInfo._id}`, {
          email: this.userDataInfo.guardian[0].email,
          name: this.userDataInfo.guardian[0].name,
          phone: this.userDataInfo.guardian[0].phone,
        })
        .then((result) => {
          if (result.status === 201) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("Parent Account Created Successfully"),
                icon: "UserPlusIcon",
                variant: "success",
              },
            });
            this.userDataInfo.parentAccountCreated = true;
          }
        });
    },
    resetParentPassword() {
      axios
        .post(`${parentPath.reset}/${this.userDataInfo._id}`, {
          email: this.userDataInfo.guardian[0].email,
        })
        .then((result) => {
          if (result.status === 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Şifre Sıfırlama Maili Gönderildi",
                icon: "LockIcon",
                variant: "success",
              },
            });
            this.userDataInfo.parentAccountCreated = true;
          }
        });
    },
    emailControl() {
      const email = this.userDataInfo.guardian[0].email;
      //check if email is valid
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regex.test(email)) {
        this.userDataInfo.guardian[0].email = "";
        this.$swal({
          title: this.$t("Email is not valid"),
          icon: "error",
          confirmButtonText: this.$t("Ok"),
          // timer: 3000,
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } else {
        if (
          this.userParentEmail != this.userData.guardian[0].email &&
          this.userDataInfo.parentAccountCreated
        ) {
          this.$swal({
            title: this.$t("Parent Account Exists"),
            text: this.$t(
              "Do you want to create a new parent account and delete the old one?"
            ),
            icon: "warning",
            confirmButtonText: this.$t("Yes"),
            cancelButtonText: this.$t("Cancel"),
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              // this.userDataInfo.parentAccountCreated = true;
              this.createParentAccount();
            }
          });
        } else if (
          !this.userDataInfo.parentAccountCreated &&
          this.userDataInfo.guardian[0].name
        ) {
          this.$swal({
            title: this.$t("Parent Account"),
            text: this.$t("Do you want to create a parent account?"),
            icon: "info",
            confirmButtonText: this.$t("Yes"),
            cancelButtonText: this.$t("No"),
            showCancelButton: true,
          }).then((result) => {
            if (result.isConfirmed) {
              // this.userDataInfo.parentAccountCreated = true;
              this.createParentAccount();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
