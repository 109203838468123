<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar ref="previewEl" :src="userData.avatar" :text="avatarText(userData.fullName)"
          :variant="`light-${resolveUserRoleVariant(userData.role)}`" size="90px" rounded />
      </template>
      <h4 class="mb-1">
        {{ userData.fullName }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input ref="refInputEl" type="file" class="d-none" @input="inputImageRenderer" />
          <span class="d-none d-sm-inline">{{ $t("Update") }}</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">{{ $t("Remove") }}</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="" label-for="full-name" class="mt-1">
            <h4 id="full-name">{{ userData.name }}</h4>
          </b-form-group>
        </b-col>
        <!-- Field: Age -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Age')" label-for="user-age">
            <b-form-input id="user-age" v-model="userData.age" />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Email Address')" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>
        <!-- Field: Phone -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Parent Phone')" label-for="phone">
            <b-form-input id="phone" v-model="userData.guardian[0].phone" type="tel" />
          </b-form-group>
        </b-col>
        <!-- Field: Status -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Status')" label-for="user-status">
            <v-select v-model="userData.status" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusOptions" :reduce="(val) => val.value" :clearable="false" input-id="user-status" />
          </b-form-group>
        </b-col>
        <!-- <b-col cols="12" md="4">
          <b-form-group label="Grup" label-for="user-role">
            <v-select
              v-model="userData.subjectClass"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-role"
              @input="alertClassChangeF"
            />
          </b-form-group>
        </b-col> -->
        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group :label="$t('Parent Name')" label-for="guardian-name">
            <b-form-input id="guardian-name" v-model="userData.guardian[0].name" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-button variant="primary" class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'" @click="saveUserData">
      {{ $t("Save Changes") }}
    </b-button>
    <b-button variant="outline-secondary" type="reset" :block="$store.getters['app/currentBreakPoint'] === 'xs'">
      {{ $t("Reset") }}
    </b-button>
    <!-- <div>
      <b-modal
        v-model="classChangeModal"
        centered
        title="Danışan Grup Değişikliği"
        ok-title="Kabul Ediyorum"
        cancel-title="Vazgeç"
        ok-variant="success"
        cancel-variant="danger"
        size="lg"
        @ok="handleConfirm"
        @cancel="handleCancel"
      >
        <b-row class="modal-h">
          <b-col
            ><span
              >Diko Sizler için danışanlarınızın Diko sistemi içindeki derecelerini
              belirlemek adına en iyi grubu seçer.</span
            ></b-col
          >
        </b-row>
        <b-row class="mt-2">
          <b-col
            ><span
              >Danışanlarınızın grubunu değiştirmek istediğinize emin misiniz?</span
            ></b-col
          >
        </b-row>
      </b-modal>
    <div> -->
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BModal,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import i18n from "@/libs/i18n";

const {
  axiosRoutes: { subject: path },
} = require("/G_CONFIG");

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BModal,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      alertChange: 0,
      classChangeModal: false,
      beforeChangeClass: "",
    };
  },
  created() {
    console.log(this.userData);
    this.beforeChangeClass = this.userData.subjectClass;
  },
  setup(props) {
    const { resolveUserRoleVariant } = useUsersList();

    const roleOptions = [
      { label: "A", value: "A" },
      { label: "B", value: "B" },
      { label: "C", value: "C" },
      { label: "D", value: "D" },
      { label: "E", value: "E" },
      // { label: "RET", value: "RET" },
    ];

    const statusOptions = [
      { label: i18n.t("Pending"), value: "pending" },
      { label: i18n.t("Active"), value: "active" },
      { label: i18n.t("Inactive"), value: "inactive" },
    ];

    /* const permissionsData = [
      {
        module: "Admin",
        read: true,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "Staff",
        read: false,
        write: true,
        create: false,
        delete: false,
      },
      {
        module: "Author",
        read: true,
        write: false,
        create: true,
        delete: false,
      },
      {
        module: "Contributor",
        read: false,
        write: false,
        create: false,
        delete: false,
      },
      {
        module: "User",
        read: false,
        write: false,
        create: false,
        delete: true,
      },
    ];
 */
    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, (base64) => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64;
    });

    return {
      resolveUserRoleVariant,
      avatarText,
      roleOptions,
      statusOptions,
      /* permissionsData, */

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      inputImageRenderer,
    };
  },
  methods: {
    alertClassChangeF() {
      const { subjectClass } = this.userData;
      this.classChangeModal = !this.classChangeModal;
    },
    handleConfirm() {
      console.log(`Confirmed!${this.userData.subjectClass}`);
      console.log(this.userData);
    },
    handleCancel() {
      this.userData.subjectClass = this.beforeChangeClass;
      console.log(`Cancelled!${this.userData.subjectClass}`);
    },
    async saveUserData() {
      console.log(this.userData);
      await axios.patch(`${path.edit}/${this.$route.params.id}`, this.userData);
      this.$swal({
        title: i18n.t("Changes Saved Successsfully"),
        icon: "success",
        confirmButtonText: i18n.t("Ok"),
        timer: 3000,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      setTimeout(() => {
        this.$router.back();
      }, 3000);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.modal-h {
  margin-bottom: 1rem;
  height: 100%;
}
</style>
